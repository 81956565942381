const staging = {
  apiKey: "AIzaSyBaEvX2lYMFNi6WYWyeQl9FapgHw-5qdTA",
  authDomain: "eras-dev.firebaseapp.com",
  projectId: "eras-dev",
  storageBucket: "eras-dev.appspot.com",
  messagingSenderId: "128277416478",
  appId: "1:128277416478:web:bba92f68d2e95d909ffc0f",
  measurementId: "G-5H6L9HKDPZ",
}

const production = {
  apiKey: "AIzaSyAZV-2GPAI4QD10FKhvLj72pg1O90FQNkI",
  authDomain: "eras-staging.firebaseapp.com",
  projectId: "eras-staging",
  storageBucket: "eras-staging.appspot.com",
  messagingSenderId: "54345885621",
  appId: "1:54345885621:web:c2494d265c512e6d8d5bfc",
}

const firebaseConfig = () => {
  if (process.env.GATSBY_ENV === "production") return production
  return staging
}

export default firebaseConfig
