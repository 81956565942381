import React from "react"

import WrapWithProviders from "./WrapWithProviders"

export const wrapRootElement = ({ element }) => (
  <WrapWithProviders>{element}</WrapWithProviders>
)

// ================ SILENT AUTH ==================================
// class SessionCheck extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       loading: true,
//     }
//   }
//
//   handleCheckSession = () => {
//     this.setState({ loading: false })
//   }
//
//   componentDidMount() {
//     silentAuth(this.handleCheckSession)
//   }
//
//   render() {
//     return (
//       this.state.loading === false && (
//         <React.Fragment>{this.props.children}</React.Fragment>
//       )
//     )
//   }
// }
// ================ SILENT AUTH ==================================
