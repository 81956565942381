const onboardingSteps = {
  step1: "step-1",
  step2: "step-2",
  step3: "step-3",
  step4: "step-4",
  step5: "step-5",
  step6: "step-6",
  step7: "step-7",
  step8: "step-8",
  final: "final",
  confirmation: "payment-confirmed",
}

export default onboardingSteps
