import React, { useEffect } from "react"

const Base = ({ children }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const UIkit = require("uikit")
      const Icons = require("uikit/dist/js/uikit-icons")
      UIkit.use(Icons)
    }
    const revealPage = setTimeout(() => {
      document.body.classList.add("revealed")
    }, 20)
    return () => {
      clearTimeout(revealPage)
    }
  }, [])
  return <>{children}</>
}

export default Base
