import React from "react"
import Base from "./src/Layout/Base"
import "uikit/dist/css/uikit.min.css"
import "./src/styles/fonts.css"
import "./src/styles/base.less"
import { AuthProvider } from "./src/hooks/userUser"

const WrapWithProviders = ({ children }) => {
  return (
    <AuthProvider>
      <Base>{children}</Base>
    </AuthProvider>
  )
}

export default WrapWithProviders
